<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Receivable Aging"
            />
            <div class="row match-height">
                <div class="col-12 col-lg-12 col-xl-12 col-xxl-12 mt-2">
                    <DateQuerySetter
                        @onClickGo="getReceivables"
                        :isVisibleFromDate="false"
                    >
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Days Per Aging Period</label>
                        <input type="text" v-model="daysPerAgingPeriod"  class="form-control" />
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Number Of Periods</label>
                        <input type="text" v-model="numberOfPeriod"  class="form-control" />
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Roles</label>
                        <v-select
                            placeholder="Select Roles"
                            v-model="company_role_id"
                            :options="companyRoles"
                            label="name"
                            :reduce="name => name.id"
                        />
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                        <label for="colFormLabel" class="col-form-label">Party</label>
                        <AsyncSelect
                            placeholder="Party Name, ID, Mobile No"
                            v-model="contactProfile"
                            :api-service="fetchContactProfiles"
                            :format-label="formatPatientLabel"
                        />
                      </div>
                    </DateQuerySetter>
                </div>
              <div class="d-flex justify-content-end px-2 mt-2 mb-2 mr-2">
                <button class="btn btn-primary btn-block waves-effect waves-float waves-light print-btn me-2" @click="goToPrint">
                  Print
                </button>
                <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="exportReport">
                  Export
                </button>
              </div>
            </div>
        </div>

        <div class="mt-2 px-2">
            <ReceivableAgingTable
                :tableData="receivable"
                :agingPeriods="agingPeriods"
            />
        </div>
      <div class="px-2">
        <Pagination
            :offset="offset"
            @onPageChange="onPageChange"
            ref="pagination"
        />
      </div>
      <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import Loader from "@/components/atom/LoaderComponent.vue";
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ReceivableAgingTable from '@/components/molecule/company/report/ReceivableAgingTable.vue'
import useReport from '@/services/modules/dashboard/report.js'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleRole from "@/services/modules/role";
import handleContact from "@/services/modules/contact";

import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCompany from "@/services/modules/company";
import pdfPrinter from "@/services/utils/pdf/payableRegister";
import {useStore} from "vuex";
import Pagination from "@/components/atom/Pagination.vue";
const { fetchReceivableAging } = useReport()
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF, formatMoneyReceiptData } = pdfPrinter();

const route = useRoute()
const router = useRouter();
const store = useStore();

let offset = ref(200)
const loading = ref(false);

const receivable = ref([])
const agingPeriods = ref([])
const companyRoles = ref([])
const company_role_id = ref(null)
const daysPerAgingPeriod = ref(30)
const numberOfPeriod = ref(3)
const contactProfile = ref(null);
const companyInfo = ref(null);
const isFetchAll = ref(false);
const receivablePdfData = ref([]);

onMounted(() => {
  getDefaultRoles();
  getCompany();
})

function getQuery () {
    let queryStr = '?company_id=' + route.params.companyId
    queryStr += '&offset=' + offset.value
    if (route.query.page) queryStr += "&page=" + route.query.page;
    if(company_role_id.value) queryStr += '&company_role_id=' + company_role_id.value;
    if(contactProfile.value) queryStr += '&contact_profile_id=' + contactProfile.value.id;
    if(daysPerAgingPeriod.value) queryStr += '&days_per_aging_period=' + daysPerAgingPeriod.value;
    if(numberOfPeriod.value) queryStr += '&number_of_period=' + numberOfPeriod.value;
    if(isFetchAll.value) queryStr += '&is_fetch_all=' + isFetchAll.value;
    if(!route.query.end) return queryStr
    return queryStr+`&receivable_date={
    "start": "${route.query.start}",
    "end": "${route.query.end}"
    }`
}

const getReceivables = async () => {
  loading.value = true;
  try {
    const res = await fetchReceivableAging(getQuery());

    if (!res.status) {
      receivable.value = []
      agingPeriods.value = [];
      return store.commit("resetPagination")
    }

    if(isFetchAll.value){
      receivablePdfData.value = res.data;
      return;
    }

    receivable.value = res.data.data;
    agingPeriods.value = res.aging_periods;

    if (receivable.value.length > 0) {
      setPagination(res.data)
    }

  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false;
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getReceivables()
}
const setPagination = (data) => {
  setPaginationData({
    from: data.from,
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const getDefaultRoles = async () => {
  const companyQuery = `?company_id=${route.params.companyId}`
  const res = await fetchCompanyDefaultRoles(companyQuery)
  if(res.status) {
    companyRoles.value = res.data;
  }
}

const getCompany = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo.value = res.data;
  })
}

const goToPrint = async() => {

  const payload = {
    startDate: route.query.start,
    endDate: route.query.end,
    role: '',
    party: contactProfile.value ? contactProfile.value.name : '',
  };

  if(company_role_id.value){
    const role = companyRoles.value.find(item => item.id === company_role_id.value)
    payload.role = role.name;
  }

  isFetchAll.value = true;

  await getReceivables().then(() => {
    if(!receivablePdfData.value.length) return;
    const formattedData = formatMoneyReceiptData(receivablePdfData.value, 'CUSTOMER')
    exportToPDF(companyInfo.value, formattedData, payload, 'Receivable')
  })

  isFetchAll.value = false;
}

const exportReport = () => {
  let token = localStorage.getItem('token');
  let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/receivable${getQuery()}&_token=${token}&is_export=1`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}
</script>
